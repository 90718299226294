// import { BigNumber } from "ethers";

type NFTV2Type = {
  name: string;
  img: string;
  id: string;
};

type NFTV3Type = {
  name: string;
  img: string;
  description: string;
  id: string;
  attributes: {
    trait_type: string;
    value: string;
  }[];
};

enum Level {
  UNKNOWN = 0,
  BRONZE = 1,
  SILVER = 2,
  GOLD = 3,
}

// type NFTData = {
//   level: Level;
//   randomValue: string;
//   timestamp: BigNumber;
// };

type NFTType = NFTV2Type | NFTV3Type;
export type { NFTV2Type, NFTV3Type, NFTType };
export { Level };
