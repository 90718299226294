import NftImage from "img/nft2.png";
import type { NFTV3Type } from "hooks/types";
import { ethers } from "ethers";
import { useState, useEffect, useMemo } from "react";
import FoxifyTradingNFT from "abis/common/FoxifyTradingNFT.json";

type AttributeT = {
  trait_type: string;
  value: string;
};

type MetadataT = {
  name: string;
  description: string;
  image: string;
  attributes: AttributeT[];
};

export const useNFTV3 = (isActive: boolean, chainId: number, holderAddress: string = "") => {
  const [balanceOf, setBalanceOf] = useState<number | null>(null);
  const [ids, setIds] = useState<string[]>([]);
  const [nfts, setNfts] = useState<NFTV3Type[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const provider = useMemo(() => {
    return new ethers.providers.FallbackProvider([
      new ethers.providers.JsonRpcProvider("https://arbitrum-one-rpc.publicnode.com"),
      new ethers.providers.JsonRpcProvider("https://arbitrum.drpc.org"),
    ]);
  }, []);

  // 1) Fetch balanceOf
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        setLoading(true);
        const contract = new ethers.Contract(
          FoxifyTradingNFT.address,
          FoxifyTradingNFT.abi,
          provider
        );
        // For V3, presumably "usersIDsLength" returns a BigNumber
        const balanceBN = await contract.usersIDsLength(holderAddress);
        setBalanceOf(balanceBN.toNumber());
      } catch (error) {
        setBalanceOf(0);
      }
    };

    if (isActive && holderAddress) {
      fetchBalance();
    } else {
      // Not connected => reset
      setBalanceOf(null);
      setIds([]);
      setNfts([]);
      setLoading(false);
    }
  }, [isActive, holderAddress, chainId, provider]);

  // 2) Fetch IDs
  useEffect(() => {
    const fetchIds = async () => {
      if (balanceOf && balanceOf > 0) {
        try {
          const contract = new ethers.Contract(
            FoxifyTradingNFT.address,
            FoxifyTradingNFT.abi,
            provider
          );
          const idsArray = await contract.usersIDsList(holderAddress, 0, balanceOf);
          setIds(idsArray.map((id: ethers.BigNumber) => id.toString()));
        } catch (error) {
          setIds([]);
        }
      } else if (balanceOf === 0) {
        setIds([]);
      }
    };

    if (balanceOf !== null) {
      fetchIds();
    }
  }, [balanceOf, chainId, holderAddress, provider]);

  // 3) Fetch metadata for each NFT
  useEffect(() => {
    const fetchNFTMetadata = async () => {
      try {
        // For each NFT ID, fetch metadata from your custom endpoint
        const nftPromises = ids.map(async (id) => {
          try {
            const response = await fetch(`https://public-api.foxify.trade/nfts/${id}`, {
              mode: "cors",
            });
            const metadata = (await response.json()) as MetadataT;

            return {
              id,
              name: `${metadata.name} #${id}`,
              description: metadata.description,
              img: metadata.image || NftImage,
              attributes: metadata.attributes || [],
            };
          } catch (error) {
            // Fallback
            return {
              id,
              name: `Foxify #${id}`,
              description: "Error fetching metadata",
              img: NftImage,
              attributes: [],
            };
          }
        });

        const resolvedNFTs = await Promise.all(nftPromises);
        setNfts(resolvedNFTs);
      } catch (error) {
        setNfts([]);
      } finally {
        setLoading(false);
      }
    };

    // If we have IDs, fetch metadata; otherwise, if we have 0 but a valid balanceOf,
    // then we know user has no NFTs => setLoading(false)
    if (ids.length > 0) {
      fetchNFTMetadata();
    } else if (balanceOf !== null) {
      setNfts([]);
      setLoading(false);
    }
  }, [ids, balanceOf]);

  return {
    balanceOf,
    ids,
    nfts,
    isLoading: loading,
  };
};
