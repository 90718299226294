import FoxifyTradingNFT from "abis/common/FoxifyTradingNFT.json";
import FoxifyAffiliation from "abis/common/FoxifyAffiliation.json";
// import Trader from "abis/common/FundedTrader.json";
import { BigNumber, ethers } from "ethers";
import { Trans, t } from "@lingui/macro";
import { callContract } from "lib/contracts";
import { helperToast } from "lib/helperToast";
import { Web3Provider } from "@ethersproject/providers";
import { useState } from "react";
import { useNftAllowance } from "hooks/useNftAllowance";
// import { useFundedTrader } from "hooks/useFundedTrader";
import clsx from "clsx";

type NFTRenderItemProps = {
  isActive: boolean;
  name: string;
  img?: string;
  id?: string; // NFT tokenId
  v3: boolean; // True if NFT is from V3, false if from V2
  tier?: string; // e.g. "Bronze", "Silver", "Gold"
  chainId: number;
  holderAddress: string;
  library: Web3Provider;
};

export const NFTRenderItem = ({
  isActive,
  name,
  id,
  img,
  v3,
  tier, // Bronze, Silver, Gold
  chainId,
  holderAddress,
  library,
}: NFTRenderItemProps) => {
  const [isMigrating, setIsMigrating] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  // const [isStakingOrUnstaking, setIsStakingOrUnstaking] = useState(false);
  const isStakingOrUnstaking = false

  // Addresses
  const v2Address = FoxifyAffiliation.address;
  const foxifyTradingNFTAddress = FoxifyTradingNFT.address;
  // const { proxyTraderContractAddress: traderAddress } = useFundedTrader();

  // For checking NFT allowance (only relevant if migrating V2 -> V3)
  const { approve, haveApproved } = useNftAllowance(
    v2Address,
    holderAddress,
    foxifyTradingNFTAddress,
    id ? BigNumber.from(id) : BigNumber.from(0)
  );

  // Migrate from V2 -> V3
  const handleMigrate = async () => {
    if (!library || !id) {
      helperToast.error(t`Cannot migrate. Missing wallet or tokenId.`);
      return;
    }

    let signer;
    try {
      signer = library.getSigner();
      await signer.getAddress();
    } catch {
      helperToast.error(t`Unable to retrieve signer. Please connect a wallet.`);
      return;
    }

    // 'migrate(uint256[] memory versions, uint256[] memory tokenIds)'
    const versionsArray = [BigNumber.from(1)]; // version=1 => v2
    const tokenIdsArray = [BigNumber.from(id)];
    const contract = new ethers.Contract(
      foxifyTradingNFTAddress,
      FoxifyTradingNFT.abi,
      signer
    );

    try {
      // Approve if needed
      if (!haveApproved(BigNumber.from(id))) {
        setIsApproving(true);
        const tx = await approve(BigNumber.from(id));
        if (tx) {
          await tx.wait();
        }
        setIsApproving(false);
      }

      setIsMigrating(true);
      await callContract(chainId, contract, "migrate", [versionsArray, tokenIdsArray], {
        sentMsg: t`Migrating NFT submitted.`,
        successMsg: t`NFT migrated successfully!`,
        failMsg: t`Migrating NFT failed`,
      });
    } catch (e: any) {
      helperToast.error(
        e.reason
          ? t`Migrating NFT failed: ${e.reason}`
          : t`Migration failed. Please try again.`
      );
    } finally {
      setIsApproving(false);
      setIsMigrating(false);
    }
  };

  const handleStakeToggle = async () => {

    if(true) {
      helperToast.error(t`Staking is currently unavailable.`);
      return;
    }

    // if (!library || !id || !traderAddress) {
    //   helperToast.error(t`Missing wallet, tokenId, or Trader contract address.`);
    //   return;
    // }
  
    // let signer;
    // try {
    //   signer = library.getSigner();
    //   await signer.getAddress();
    // } catch {
    //   helperToast.error(t`Unable to retrieve signer or address. Please connect properly.`);
    //   return;
    // }
  
    // const contract = new ethers.Contract(traderAddress, Trader.abi, signer);
    // setIsStakingOrUnstaking(true);
  
    // try {
    //   // First, attempt a static call to unlock.
    //   // If the NFT is currently staked, unlockFoxifyNft should succeed.
    //   await contract.callStatic.unlockFoxifyNft();
    //   // NFT is staked; perform an actual unlock.
    //   await callContract(chainId, contract, "unlockFoxifyNft", [], {
    //     sentMsg: t`Unstaking NFT...`,
    //     successMsg: t`NFT unstaked successfully!`,
    //     failMsg: t`Unstake failed`,
    //   });
    // } catch (err: any) {
    //   // We check if the error indicates that there is no NFT locked.
    //   // The FundedTrader contract may revert with a "NoNftToUnlock" error.
    //   const errorMsg = err?.reason || err?.message || "";
    //   if (errorMsg.includes("NoNftToUnlock")) {
    //     // If there is no NFT staked, we proceed to stake (lock) it.
    //     try {
    //       await callContract(chainId, contract, "lockFoxifyNft", [id], {
    //         sentMsg: t`Staking NFT...`,
    //         successMsg: t`NFT staked successfully!`,
    //         failMsg: t`Staking NFT failed`,
    //       });
    //     } catch (stakeErr: any) {
    //       helperToast.error(
    //         stakeErr.reason
    //           ? t`Staking NFT failed: ${stakeErr.reason}`
    //           : t`Staking NFT failed. Please try again.`
    //       );
    //     }
    //   } else {
    //     // For any other errors, surface them to the user.
    //     helperToast.error(
    //       errorMsg
    //         ? t`Staking/Unstaking NFT failed: ${errorMsg}`
    //         : t`Staking/Unstaking NFT failed. Please try again.`
    //     );
    //   }
    // } finally {
    //   setIsStakingOrUnstaking(false);
    // }
  };

  // Return text for the main action button
  const getPrimaryText = () => {
    if (!v3) {
      // Migration flow
      if (isApproving) return t`Approving...`;
      if (isMigrating) return t`Migrating...`;
      return t`Migrate from V2`;
    }
    // Stake/unstake flow
    if (isStakingOrUnstaking) {
      return t`Processing...`;
    }
    return t`Stake / Unstake`;
  };

  const handleClickPrimary = () => {
    // If user is in the middle of an action, skip
    if (isApproving || isMigrating || isStakingOrUnstaking) return;

    if (v3) {
      handleStakeToggle();
    } else {
      handleMigrate();
    }
  };

  return (
    <div
      className={clsx(
        "flex flex-col rounded-[15px] bg-[#101014] border border-[rgba(255,255,255,0.08)] p-2.5",
        "flex-1 min-w-[150px] max-w-[270px] basis-[calc(50%-1rem)]"
      )}
    >
      <img src={img} alt={name} className="object-cover rounded-[10px] mb-4" />

      <div className="flex justify-between">
        <p className="text-lg">{name}</p>
        <p className="h-6 px-4 overflow-visible flex items-center rounded-[10px] bg-[#202024]">
          {`#${id?.toString()}`}
        </p>
      </div>

      <button
        onClick={handleClickPrimary}
        disabled={
          isApproving ||
          isMigrating ||
          isStakingOrUnstaking ||
          tier === "Bronze"
        }
        className="w-full text-main px-6 py-4 Create-funded-btn rounded h-16 mt-4 disabled:opacity-50 disabled:hover:bg-main disabled:hover:text-black"
      >
        <Trans>{getPrimaryText()}</Trans>
      </button>
    </div>
  );
};
