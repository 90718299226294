import { NFTRenderItem } from "./NFTRenderItem";
import { Web3Provider } from "@ethersproject/providers";
import { useNFTV3 } from "hooks/useNFTV3";
import { MergeNFTModal } from "./MergeModal";

type NFTMergeProps = {
  isActive: boolean;
  chainId: number;
  holderAddress: string;
  library: Web3Provider;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const NFTMerge = ({ isActive, chainId, holderAddress, library, open, setOpen }: NFTMergeProps) => {
  // useNFTV3 is a custom hook presumably returning the user’s NFTs from the FoxifyTradingNFT (V3)
  const { nfts, isLoading } = useNFTV3(isActive, chainId, holderAddress);

  return (
    <>
      {isLoading && (
        <div className="flex justify-center items-center w-full h-[400px] text-[14px] text-[#8C8C8C]">Loading...</div>
      )}

      {!isLoading && nfts.length === 0 && (
        <div className="flex justify-center items-center w-full h-[400px] text-[14px] text-[#8C8C8C]">
          No NFTs found
        </div>
      )}
      {!isLoading && nfts.length > 0 && (
        <div className="flex flex-wrap gap-8 justify-center">
          {nfts.map((nft, index) => (
            <NFTRenderItem
              isActive={isActive}
              key={index}
              name={nft.name}
              img={nft.img}
              id={nft.id}
              v3={true}
              tier={nft.attributes?.find((attr) => attr.trait_type === "Necklace")?.value}
              chainId={chainId}
              holderAddress={holderAddress}
              library={library}
            />
          ))}
        </div>
      )}

      {/* Merge Modal */}
      <MergeNFTModal open={open} setOpen={setOpen} nfts={nfts} chainId={chainId} library={library} />
    </>
  );
};
