import { t, Trans } from "@lingui/macro";
import NftImage from "../../../../img/nft2.png";
import { ethers } from "ethers";
import { helperToast } from "lib/helperToast";
import { callContract } from "lib/contracts";
import { useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { StandardMerkleTree } from "@openzeppelin/merkle-tree";

import FoxifyTradingNFT from "abis/common/FoxifyTradingNFT.json";
// UPDATED: Make sure you have your local or imported whitelist
import ALLOWED_ADDRESSES from "./whitelist.json";

type NFTNewMintProps = {
  chainId: number;
  library: Web3Provider;
};

export const NFTNewMint = ({ chainId, library }: NFTNewMintProps) => {
  const [isMinting, setIsMinting] = useState(false);

  // Generate Merkle Proof
  const getMerkleProof = (address: string) => {
    const leaves = ALLOWED_ADDRESSES.map((addr) => [addr]);
    const tree = StandardMerkleTree.of(leaves, ["address"]);
    const root = tree.root;
    // console.log('root', root)
    
    for (const [i, value] of tree.entries()) {
      if (value[0] === address) {
        const proof = tree.getProof(i);
        // console.log("Value:", value);
        // console.log("Proof:", proof);
        // const isValid = StandardMerkleTree.verify(root, ['address'], [address], proof);
        // console.log("Is valid:", isValid);
        return { root, proof };
      }
    }
    
    return { root, proof: [] };
  };

  const processMint = async () => {
    if (!library) {
      helperToast.error(t`No wallet library found. Please connect a wallet.`);
      return;
    }
    let signer;
    let address;
    try {
      signer = library.getSigner();
      address = await signer.getAddress();
    } catch {
      helperToast.error(t`Unable to retrieve signer or address. Please connect properly.`);
      return;
    }

    const foxifyTradingNFTAddress = FoxifyTradingNFT.address;
    const contract = new ethers.Contract(
      foxifyTradingNFTAddress,
      FoxifyTradingNFT.abi,
      signer
    );

    setIsMinting(true);
    try {
      const {proof} = getMerkleProof(address);
      // console.log('Merkle root:', root);
      // console.log("Merkle proof:", proof);

      if (!proof || proof.length === 0) {
        helperToast.error(t`Your address is not whitelisted or proof generation failed.`);
        setIsMinting(false);
        return;
      }
      const params = [proof];

      await callContract(chainId, contract, "mintRequest", params, {
        sentMsg: t`Minting NFT submitted.`,
        successMsg: t`NFT minted successfully!`,
        failMsg: t`Minting NFT failed`,
      });
    } catch (e: any) {
      // Show fallback error if e.reason is not available
      helperToast.error(
        e.reason
          ? t`Minting NFT failed: ${e.reason}`
          : t`Minting NFT failed. Please check console or try again.`,
        { autoClose: false }
      );
    } finally {
      setIsMinting(false);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-between items-center min-w-[150px] max-w-[270px] basis-[calc(50%-1rem)] h-[414px] bg-white bg-opacity-5 rounded-[10px] p-[10px]">
        <div className="m-auto">
          <div className="flex flex-col justify-center items-center rounded-[10px] border bg-[#101014] border-[rgba(255,255,255,0.08)] w-fit p-2">
            <img
              src={NftImage}
              alt="randomNFT"
              className="w-[150px] h-[150px] rounded-[10px] blur-sm mb-4"
            />
            <p className="text-center text-[14px] py-[10px]">1x Random NFT</p>
          </div>
        </div>

        <button
          onClick={processMint}
          className="text-main px-[1.7rem] text-[14px] py-[1rem] rounded-[1.5rem] Create-funded-btn h-[4.5rem] w-full"
          disabled={isMinting}
        >
          {isMinting ? <Trans>Minting...</Trans> : <Trans>Mint NFT</Trans>}
        </button>
      </div>
    </div>
  );
};
