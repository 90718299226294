import NftImage from "img/nft.png";
import type { NFTV2Type } from "hooks/types";
import { ethers } from "ethers";
import { useState, useEffect, useMemo } from "react";
import FoxifyAffiliation from "abis/common/FoxifyAffiliation.json";

export const useNFTV2 = (
  isActive: boolean,
  chainId: number,
  holderAddress: string = "",
  refresh: boolean = false
) => {
  const [balanceOf, setBalanceOf] = useState<number | null>(null);
  const [ids, setIds] = useState<string[]>([]);
  const [nfts, setNfts] = useState<NFTV2Type[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const provider = useMemo(() => {
    return new ethers.providers.FallbackProvider([
      new ethers.providers.JsonRpcProvider("https://arbitrum-one-rpc.publicnode.com"),
      new ethers.providers.JsonRpcProvider("https://arbitrum.drpc.org"),
    ]);
  }, []);

  // 1) Fetch balanceOf
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        setIsLoading(true);
        const contract = new ethers.Contract(
          FoxifyAffiliation.address,
          FoxifyAffiliation.abi,
          provider
        );
        const balanceBN = await contract.balanceOf(holderAddress);
        setBalanceOf(balanceBN.toNumber());
      } catch (error) {
        setBalanceOf(0);
      }
    };

    if (isActive && holderAddress) {
      fetchBalance();
    } else {
      // Not active or no address => reset
      setBalanceOf(null);
      setIds([]);
      setNfts([]);
      setIsLoading(false);
    }
  }, [isActive, holderAddress, chainId, provider, refresh]);

  // 2) Fetch IDs
  useEffect(() => {
    const fetchIds = async () => {
      if (balanceOf && balanceOf > 0) {
        try {
          const contract = new ethers.Contract(
            FoxifyAffiliation.address,
            FoxifyAffiliation.abi,
            provider
          );
          const idsArray = await contract.usersIDsList(holderAddress, 0, balanceOf);
          setIds(idsArray.map((id: ethers.BigNumber) => id.toString()));
        } catch (error) {
          setIds([]);
        }
      } else if (balanceOf === 0) {
        // If balance is explicitly 0, we know user has no NFTs
        setIds([]);
      }
    };

    if (balanceOf !== null) {
      fetchIds();
    }
  }, [balanceOf, chainId, holderAddress, provider, refresh]);

  // 3) Format NFTs (simple placeholder data)
  useEffect(() => {
    // If we have IDs, build them
    if (ids.length > 0) {
      const formattedNfts: NFTV2Type[] = ids.map((id) => ({
        id,
        name: `Foxify Trading Co #${id}`,
        img: NftImage,
      }));
      setNfts(formattedNfts);
      setIsLoading(false);
    } else if (balanceOf !== null) {
      // If we have no IDs and we've finished fetching (balanceOf is known),
      // then we're done loading => user has zero NFTs
      setNfts([]);
      setIsLoading(false);
    }
  }, [ids, balanceOf]);

  return {
    balanceOf,
    ids,
    nfts,
    isLoading,
  };
};
