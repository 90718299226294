import { NFTRenderItem } from "./NFTRenderItem";
import { Web3Provider } from "@ethersproject/providers";
import { useNFTV2 } from "hooks/useNFTV2"; // presumably returns V2 NFTs

type NFTMigrationProps = {
  isActive: boolean;
  chainId: number;
  holderAddress: string;
  library: Web3Provider;
};

export const NFTMigration = ({ isActive, chainId, holderAddress, library }: NFTMigrationProps) => {
  // useNFTV2 is a custom hook presumably returning the user’s NFTs from the FoxifyAffiliation (V2)
  const { nfts, isLoading } = useNFTV2(isActive, chainId, holderAddress);

  return (
    <>
      {isLoading && (
        <div className="flex justify-center items-center w-full h-[400px] text-[14px] text-[#8C8C8C]">Loading...</div>
      )}

      {!isLoading && nfts.length === 0 && (
        <div className="flex justify-center items-center w-full h-[400px] text-[14px] text-[#8C8C8C]">
          No NFTs found
        </div>
      )}

      {!isLoading && nfts.length > 0 && (
        <div className="flex flex-wrap gap-8 justify-center">
          {nfts.map((nft, index) => (
            <NFTRenderItem
              key={index}
              isActive={isActive}
              name={nft.name}
              img={nft.img}
              id={nft.id}
              v3={false} // means it's from V2
              chainId={chainId}
              holderAddress={holderAddress}
              library={library}
            />
          ))}
        </div>
      )}
    </>
  );
};
