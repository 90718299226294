import { useChainId } from "lib/chains";
import useSWR from "swr";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import React from "react";
import { ERC721__factory } from "typechain/factories/common/ERC721__factory";
import { ERC721 } from "typechain/common/ERC721";
import { getEthersContract } from "lib/contracts/getEthersContract";

const useNftontract = (contractAddress: string) => {
  const { chainId } = useChainId();
  const { provider } = useWeb3React();
  const contract = getEthersContract<ERC721>(ERC721__factory, chainId, contractAddress, provider);

  return contract;
};

export const useNftAllowance = (
  tokenAddress: string,
  holderAddress: string,
  spenderAddress: string,
  tokenId: BigNumber
) => {
  const { chainId } = useChainId();
  const { isActive, account } = useWeb3React();
  const tokenContract = useNftontract(tokenAddress);
  const [nonce, setNonce] = React.useState(0);
  const [staleFetcherKey, setStaleFetcherKey] = React.useState<any>(null);
  const [isMutating, setIsMutating] = React.useState(false);

  const fetcherKey = React.useMemo(() => {
    return account
      ? [`Token:allowance:${isActive}`, chainId, tokenAddress, holderAddress, spenderAddress, tokenId, nonce]
      : null;
  }, [account, isActive, chainId, tokenAddress, holderAddress, spenderAddress, tokenId, nonce]);

  const isPending = React.useMemo(() => {
    return staleFetcherKey !== fetcherKey || isMutating;
  }, [staleFetcherKey, fetcherKey, isMutating]);

  const query = useSWR<{ approved: string }>(
    fetcherKey,
    async ([_key, _chainId, _tokenAddress, _holderAddress, _spenderAddress, _tokenId, _nonce]: [
      key: string,
      chainId: number,
      tokenAddress: string,
      holderAddress: string,
      spenderAddress: string,
      tokenId: BigNumber,
      nonce: number
    ]) => {
      const tempFetcherKey = fetcherKey;
      const [approved] = await Promise.all([tokenContract.getApproved(tokenId)]);

      setStaleFetcherKey(tempFetcherKey);

      return {
        approved,
      };
    }
  );

  // console.log("spender", query.data?.approved, spenderAddress);

  const haveApproved = (tokenId: BigNumber) => {
    if (tokenId.eq(0)) {
      return true;
    }
    // console.log("isPending", isPending);
    // console.log("query.data", query.data);
    if (isPending || !query.data) {
      return false;
    }
    return query.data.approved === spenderAddress;
  };

  const approve = async (tokenId: BigNumber) => {
    try {
      setIsMutating(true);

      const tx = await tokenContract.approve(spenderAddress, tokenId);
      await tx.wait();

      setNonce(tx.nonce);

      return tx;
    } finally {
      setIsMutating(false);
    }
  };

  return {
    approved: query.data?.approved,
    isPending,

    haveApproved,
    approve,
  };
};
