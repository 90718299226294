import { Trans, t } from "@lingui/macro";
import ModalWithPortal from "components/Modal/ModalWithPortal";
import NftImage from "../../../../img/nft2.png";
import { useState } from "react";
import type { NFTType } from "hooks/types";
import { Level } from "hooks/types";
import { helperToast } from "lib/helperToast";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import FoxifyTradingNFT from "abis/common/FoxifyTradingNFT.json";
import { callContract } from "lib/contracts";
import { ImSpinner2 } from "react-icons/im";

type MergeNFTModalProps = {
  open: boolean;
  setOpen: (visibleModal: boolean) => void;
  nfts: NFTType[];         // Must have at least .id for each NFT
  chainId: number;
  library: Web3Provider;
};

export const MergeNFTModal = ({ open, setOpen, nfts, chainId, library }: MergeNFTModalProps) => {
  const [selectedNfts, setSelectedNfts] = useState<string[]>([]);
  const [isMerging, setIsMerging] = useState(false);

  const handleSelectNft = (id: string) => {
    // Limit selection to 5
    if (selectedNfts.includes(id)) {
      setSelectedNfts((prevSelected) => prevSelected.filter((nftId) => nftId !== id));
    } else if (selectedNfts.length < 5) {
      setSelectedNfts((prevSelected) => [...prevSelected, id]);
    } else {
      helperToast.error(t`You can only select up to 5 NFTs.`);
    }
  };

  const handleMerge = async () => {
    // Contract requires exactly 5 Bronze NFTs if going from Bronze -> Silver
    if (selectedNfts.length !== 5) {
      helperToast.error(t`Please select 5 NFTs to merge`);
      return;
    }

    if (!library) {
      helperToast.error(t`No wallet library found. Please connect a wallet.`);
      return;
    }

    let signer;
    try {
      signer = library.getSigner();
      await signer.getAddress(); // Just to confirm signer is available
    } catch {
      helperToast.error(t`Unable to retrieve signer. Please connect properly.`);
      return;
    }

    const foxifyTradingNFTAddress = FoxifyTradingNFT.address;
    const contract = new ethers.Contract(foxifyTradingNFTAddress, FoxifyTradingNFT.abi, signer);
    const params = [selectedNfts, Level.BRONZE]; // Merging from Bronze

    setIsMerging(true);
    try {
      await callContract(chainId, contract, "merge", params, {
        sentMsg: t`Merging NFT submitted.`,
        successMsg: t`NFT merged successfully!`,
        failMsg: t`Merging NFT failed`,
      });
      // Optionally reset selection and close modal after success
      setSelectedNfts([]);
      setOpen(false);
    } catch (e: any) {
      helperToast.error(
        e.reason
          ? t`Merging NFT failed: ${e.reason}`
          : t`Merging NFT failed. Please try again.`
      );
    } finally {
      setIsMerging(false);
    }
  };

  const isSelected = (id: string) => selectedNfts.includes(id);

  return (
    <ModalWithPortal
      isVisible={open}
      setIsVisible={setOpen}
      label={t`Merge NFTs`}
      showHeaderDivider
      customWidth
      description={t`You may merge 5x Bronze NFTs into 1 Silver NFT.`}
      className="tailwind overflow-y-hidden"
    >
      <div className="flex gap-10 justify-between">
        {/* Left side: List of NFTs */}
        <div>
          <div className="mb-4 text-[14px]">
            <p>
              <Trans>Select 5 Bronze NFTs</Trans>
            </p>
            <p className="text-[#b3b3b3]">
              <Trans>{`${selectedNfts?.length ?? 0}/5 Selected`}</Trans>
            </p>
          </div>
          {nfts && nfts.length > 0 ? (
            <div className="flex flex-wrap justify-center gap-6 overflow-y-auto pb-[40px] max-h-[482px]">
              {nfts.map((nft, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectNft(nft.id!)}
                  className="relative flex flex-col items-center bg-[#101014] rounded-[15px] border border-[rgba(255,255,255,0.08)] p-2 w-[160px] flex-1 max-w-[160px] h-[212px] basis-[calc(33.33%-1rem)] cursor-pointer"
                >
                  {isSelected(nft.id!) && (
                    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50" />
                  )}
                  <img
                    src={nft.img || NftImage}
                    alt="NFT"
                    className="object-cover rounded-[10px] mb-4 w-56 h-56"
                  />
                  <div className="flex justify-between w-full items-center">
                    <p className="text-base">{nft.name}</p>
                    <p className="h-6 text-base px-4 bg-[#202024] rounded-[10px]">
                      {`#${nft.id}`}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center text-[14px] text-[#8C8C8C]">
              <Trans>No NFTs found to merge</Trans>
            </div>
          )}

          {/* Hidden button for smaller screens */}
          <button
            onClick={handleMerge}
            className="Hidden-button hidden text-[14px] text-main px-[1.7rem] py-[1rem] rounded-[1.5rem] Create-funded-btn h-[4.5rem] w-full"
            disabled={isMerging}
          >
            {isMerging ? (
              <>
                <Trans>Merging</Trans> <ImSpinner2 className="animate-spin ml-2" />
              </>
            ) : (
              <Trans>Merge NFTs</Trans>
            )}
          </button>
        </div>

        {/* Right side: Confirmation UI */}
        <div className="Hidden-block">
          <div className="mb-4 text-[14px]">
            <p>
              <Trans>You Will Get</Trans>
            </p>
            <p className="text-[#b3b3b3]">
              <Trans>1 Silver after merging 5 Bronze NFTs</Trans>
            </p>
          </div>

          <div className="flex flex-col justify-between items-center w-[240px] h-[414px] bg-white bg-opacity-5 rounded-[10px] p-[10px]">
            <div className="m-auto">
              <div className="flex flex-col justify-center items-center rounded-[10px] border bg-[#101014] border-[rgba(255,255,255,0.08)] w-fit p-2">
                <img
                  src={NftImage}
                  alt="randomNFT"
                  className="w-[150px] h-[150px] rounded-[10px] blur-sm mb-4"
                />
                <p className="text-center text-[14px] py-[10px]">1x Silver NFT</p>
              </div>
            </div>

            <button
              onClick={handleMerge}
              className="text-main px-[1.7rem] text-[14px] py-[1rem] rounded-[1.5rem] Create-funded-btn h-[4.5rem] w-full"
              disabled={isMerging}
            >
              {isMerging ? (
                <>
                  <Trans>Merging</Trans>
                  <ImSpinner2 className="animate-spin ml-2" />
                </>
              ) : (
                <Trans>Merge NFTs</Trans>
              )}
            </button>
          </div>
        </div>
      </div>
    </ModalWithPortal>
  );
};
