import Select from "react-select";
import { Trans } from "@lingui/macro";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useChainId } from "lib/chains";
import { Web3Provider } from "@ethersproject/providers";
import { NFTMigration } from "./NFTMigration";
import { NFTMerge } from "./NFTMerge";
import { NFTNewMint } from "./NFTNewMint";

import "./NFTStaking.css";

// Options for the select box
const mockOptions = [
  { value: "V2 NFT", label: "V2 NFT" },
  { value: "V3 NFT", label: "V3 NFT" },
  { value: "New Mint", label: "New Mint" },
];

export const NFTStakingTab = () => {
  const { chainId } = useChainId();
  const { isActive, account, provider } = useWeb3React();
  const holderAddress = account ? account.toLowerCase() : "";
  const [selectRarity, setSelectRarity] = useState("V2 NFT");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRarityChange = (selectedOption: any) => {
    setSelectRarity(selectedOption.value);
  };

  return (
    <div className="Tab-content flex-column z-30">
      <div className="inactive-text Fund-tab-description">
        <Trans>
          A collection of NFTs by Foxify that you own may qualify you for additional collateral by staking.
        </Trans>
      </div>

      {/* Dropdown & Merge button */}
      <div className="flex justify-between items-center mb-6 h-[4.5rem]">
        <Select
          value={mockOptions.find((option) => option.value === selectRarity)}
          onChange={handleRarityChange}
          options={mockOptions}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: (provided) => ({
              ...provided,
              width: "16.3rem",
              height: "3.5rem",
              borderRadius: "0.8rem",
              backgroundColor: "var(--pallete-background-3)",
              border: "1px solid var(--pallete-background-3)",
              color: "var(--pallete-border)",
              boxShadow: "none",
            }),
            menu: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-3)",
              border: "1px solid var(--pallete-border)",
            }),
            option: (provided) => ({
              ...provided,
              backgroundColor: "var(--pallete-background-2)",
              cursor: "pointer",
              "&:active": {
                backgroundColor: "var(--pallete-background-3)",
                outline: "none",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: "var(--pallete-inactive)",
            }),
          }}
        />

        {/* Show "Merge NFTs" only if user selected "V3 NFT" */}
        {selectRarity === "V3 NFT" && (
          <button
            onClick={() => setIsModalOpen(!isModalOpen)}
            className="w-[15rem] text-main px-[1.7rem] Create-funded-btn rounded-[1.5rem] h-full"
          >
            <Trans>Merge NFTs</Trans>
          </button>
        )}
      </div>

      <div className={selectRarity === "V2 NFT" ? "block" : "hidden"}>
        <NFTMigration
          isActive={isActive}
          chainId={chainId}
          holderAddress={holderAddress}
          library={provider as Web3Provider}
        />
      </div>
        <div className={selectRarity === "V3 NFT" ? "block" : "hidden"}>
        <NFTMerge
          isActive={isActive}
          chainId={chainId}
          holderAddress={holderAddress}
          library={provider as Web3Provider}
          open={isModalOpen}
          setOpen={setIsModalOpen}
        />
        </div>
      {selectRarity === "New Mint" && (
        <NFTNewMint chainId={chainId} library={provider as Web3Provider} />
      )}
    </div>
  );
};
